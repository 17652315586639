import { identify } from "mixpanel-browser";

// Load the ConfigCat script
const script: HTMLScriptElement = document.createElement("script");
script.type = "text/javascript";
script.src =
  "https://cdn.jsdelivr.net/npm/configcat-js@latest/dist/configcat.min.js";
document.head.appendChild(script);

script.onload = function () {
  const configCatClient = (window as any).configcat.getClient(
    "NBTcCM86OkWHkbT5R59NKw/zffXPKX7FkWTZhOtjSYUxA"
  );
  configCatClient
    .getValueAsync("aiagentv2_voye_app", false, {
      id: `${new Date()}-${Math.random().toString(36).substr(2, 9)}`,
    })
    .then((value: boolean) => {
      console.log({ value });
      if (!value) {
        // Inject the voyeglobal Zendesk Widget script
        const zendeskScript: HTMLScriptElement =
          document.createElement("script");
        zendeskScript.id = "ze-snippet";
        zendeskScript.src =
          "https://static.zdassets.com/ekr/snippet.js?key=a68da0d8-fc8b-406d-a408-ec9ea63acfe0";
        document.body.appendChild(zendeskScript);
      } else {
        // Inject the Quack script
        (function (q: any, u: Document, a: string, c: string, k: string) {
          q[a] =
            q[a] ||
            function () {
              (q[a].q = q[a].q || []).push(arguments);
            };
          const i: any = u.createElement(c);
          i.async = true;
          i.id = "quack-license";
          i.src = "https://sdk-quack.s3.amazonaws.com/p/verify.min.js";
          i.setAttribute(a, k);
          const o: Element | null = u.getElementsByTagName(c)[0];
          if (o && o.parentNode) {
            o.parentNode.insertBefore(i, o);
          }
        })(window, document, "quack", "script", "JuoArAddgBoCEm0CRkOM111");
      }
    });
};
